exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-change-password-index-jsx": () => import("./../../../src/pages/change-password/index.jsx" /* webpackChunkName: "component---src-pages-change-password-index-jsx" */),
  "component---src-pages-code-index-jsx": () => import("./../../../src/pages/code/index.jsx" /* webpackChunkName: "component---src-pages-code-index-jsx" */),
  "component---src-pages-coding-safari-privacy-policy-index-jsx": () => import("./../../../src/pages/coding-safari-privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-coding-safari-privacy-policy-index-jsx" */),
  "component---src-pages-cookies-and-privacy-policy-index-jsx": () => import("./../../../src/pages/cookies-and-privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookies-and-privacy-policy-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-primrose-index-jsx": () => import("./../../../src/pages/primrose/index.jsx" /* webpackChunkName: "component---src-pages-primrose-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-reset-password-index-jsx": () => import("./../../../src/pages/reset-password/index.jsx" /* webpackChunkName: "component---src-pages-reset-password-index-jsx" */),
  "component---src-pages-saturday-club-privacy-policy-index-jsx": () => import("./../../../src/pages/saturday-club-privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-saturday-club-privacy-policy-index-jsx" */),
  "component---src-pages-terms-of-use-index-jsx": () => import("./../../../src/pages/terms-of-use/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-jsx" */)
}

